import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faVideo, faMicrophone, faCamera, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Jitsi from 'react-jitsi';
import { CircularProgress } from '@material-ui/core';

import Layout from "../components/layout";
import { navigate } from "gatsby";

import { generateMeetingId } from '../helpers/firebase';
import { getRndNumericString } from '../helpers/util';
import { saveRecentMeeting } from "../helpers/local";

const NewMeeting = () => {
    const [loading, setLoading] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [hostVideoOn, setHostVideoOn] = useState(true);
    const [participantVideoOn, setParticipantVideoOn] = useState(false);
    const [microphoneOn, setMicrophoneOn] = useState(true);
    const [onCall, setOnCall] = useState(false);

    const handlePasswordShow = (event) => {
        if (!passwordShow && !password) {
            const password = getRndNumericString(4);
            setPassword(password);
        }
        setPasswordShow(!passwordShow);
    }

    const jitsiAPILoad = (JitsiMeetAPI) => {
        setLoading(false);
        if (!hostVideoOn) {
            JitsiMeetAPI.executeCommand('toggleVideo');
        }

        if (!microphoneOn) {
            JitsiMeetAPI.executeCommand('toggleAudio');
        }
        
        JitsiMeetAPI.addEventListener("videoConferenceLeft", () => {
            initializeInputs();
        });
        JitsiMeetAPI.addEventListener("suspendDetected", () => {
            initializeInputs();
        });
        saveRecentMeeting({
            meetingTitle: displayName,
            meetingPassword: password,
            meetingId: roomName,
            hostPreferences: {
                audio: microphoneOn,
                video: hostVideoOn,
            },
            joinedTime: Math.floor(new Date().getTime() / 1000)
        });
    }

    const initializeInputs = () => {
        setOnCall(false);
        setDisplayName('');
        setPasswordShow(false);
        setPassword('');
        setHostVideoOn(true);
        setParticipantVideoOn(false);
        setMicrophoneOn(true);
    }

    const startMeeting = async () => {
        setLoading(true);
        const data = await generateMeetingId('instant', displayName, password, hostVideoOn, microphoneOn, participantVideoOn, microphoneOn);
        setRoomName(data.meetingId);

        setOnCall(true);
    }

    return (
        <div>
            { 
                onCall && displayName && roomName && 
                <Jitsi
                    containerStyle={{ width: window.innerWidth, height: window.innerHeight }}
                    roomName={roomName} 
                    password={password}
                    displayName={displayName}
                    onAPILoad={JitsiMeetAPI => jitsiAPILoad(JitsiMeetAPI)}
                    domain="app.meetnow.in" />
            }
            {
                !onCall && 
                <Layout>
                    <div className="center_colm">
                        <div className="center_colm_title">
                            <div className="btn_back" onClick={() => navigate("/dashboard")}>
                                <FontAwesomeIcon className="fa_icon" icon={faChevronLeft} />
                            </div>
                            Start a meeting
                        </div>
                        <div className="ct_item">
                            <label htmlFor="ct_item">Enter meeting name*</label>
                            <input type="text" name="meeting_name" id="meeting_name" placeholder="Enter a meeting name" autoFocus value={displayName} onChange={(event) => setDisplayName(event.target.value)} />
                        </div>
                        <div className="options"><span>Options</span>
                            <ul className="list-unstyled">
                                <li className="m_psw">
                                    {
                                        passwordShow ? 
                                            <input type="text" name="meeting_password" id="meeting_password" className="meeting_password" placeholder="Enter a meeting password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                        :
                                            <div>
                                                <FontAwesomeIcon className="fa_icon" icon={faLock} />
                                                <a href="#">Meeting password</a>
                                            </div>
                                    }
                                    <label className="switch chk_password" htmlFor="password">
                                        <input type="checkbox" id="password" value={passwordShow} onChange={(event) => handlePasswordShow(event)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                                <li className="m_psw">
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faVideo} />
                                        <a href="#">Host video on</a>
                                    </div>
                                    <label className="switch" htmlFor="host_video_on">
                                        <input type="checkbox" id="host_video_on" defaultChecked value={hostVideoOn} onChange={(event) => setHostVideoOn(!hostVideoOn)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                                <li className="m_psw">
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faCamera} />
                                        <a href="#">Participant video on</a>
                                    </div>
                                    <label className="switch" htmlFor="participant_video_on">
                                        <input type="checkbox" id="participant_video_on" value={participantVideoOn} onChange={(event) => setParticipantVideoOn(!participantVideoOn)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                                <li className="m_psw">
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faMicrophone} />
                                        <a href="#">Microphone on</a>
                                    </div>
                                    <label className="switch" htmlFor="microphone_on">
                                        <input type="checkbox" id="microphone_on" defaultChecked value={microphoneOn} onChange={(event) => setMicrophoneOn(!microphoneOn)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                            </ul>
                            <button className="start_m_btn btn" onClick={() => startMeeting()} disabled={!displayName}>
                                {
                                    loading ? 
                                        <CircularProgress />
                                    :
                                        "Start a meeting"
                                }
                            </button>
                        </div>
                    </div>
                </Layout>
            }
        </div>
    )
}
export default NewMeeting

